<script>
import { ValidationObserver } from 'vee-validate'
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useCompetitions } from '/~/extensions/competitions/composables'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'

export default {
  name: 'competition-question-form',
  components: {
    BaseInput,
    BaseTextarea,
    BaseButton,
    BaseCheckbox,
    BaseSelect,
    ValidationObserver,
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
  },
  emits: ['submit'],
  setup(props) {
    const router = useRouter()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()
    const { withAccessCheck } = useAccess()

    const computedFormFields = computed(() => {
      const result = []

      for (const field of props.competition.formFields) {
        const type = field.type === 'phone' ? 'tel' : field.type
        const mask =
          field.type === 'phone'
            ? {
                mask: '0000 000 000',
              }
            : null
        const rules = []
        const required = Boolean(field.isRequired)

        if (required) {
          rules.push('required')
        }

        switch (field.type) {
          case 'email':
            rules.push('email')
            break
          case 'phone':
            rules.push('mobile')
            break
        }

        let is = 'base-input'

        switch (field.type) {
          case 'select':
            is = 'base-select'
            break
          case 'textarea':
            is = 'base-textarea'
            break
          case 'checkbox':
            is = 'base-checkbox'
            break
        }

        const options = field.options
          ? field.options.map((v) => ({ value: v, text: v }))
          : null

        result.push({
          rules: rules.join('|'),
          label: field.label,
          name: field.name,
          required,
          options,
          mask,
          type,
          is,
        })
      }

      return result
    })

    const cleanForm = computed(() =>
      Object.fromEntries(
        props.competition.formFields.map((field) => {
          const { name, type } = field
          let defaultValue = null

          switch (type) {
            case 'checkbox':
              defaultValue = false
              break
          }

          return [name, defaultValue]
        })
      )
    )

    const form = reactive({
      ...cleanForm.value,
    })
    const isSubmitting = ref(false)

    const resetForm = () => {
      Object.assign(form, cleanForm.value)
    }

    const canSubmit = computed(
      () =>
        !computedFormFields.value.some(
          (field) =>
            field.type === 'checkbox' &&
            /required/.test(field.rules) &&
            !form[field.name]
        )
    )

    const { competitionSubmittedDetails, submitCompetition } = useCompetitions()

    const checkboxClick = (event) => {
      if (/#terms-modal/.test(event.target.hash)) {
        const modalOptions = {
          props: {
            competition: props.competition,
          },
          on: {
            hide: () => router.replace({ hash: '' }),
          },
        }

        if (ui.mobile) {
          bottomSheet.show('competition-terms', modalOptions)
        } else {
          modal.show('competition-terms', modalOptions)
        }
      }
    }

    return {
      form,
      isSubmitting,
      computedFormFields,
      backendErrors,
      processBackendErrors,
      resetForm,
      checkboxClick,
      submitCompetition,
      competitionSubmittedDetails,
      validationObserverRef,
      withAccessCheck,
      canSubmit,
    }
  },
  methods: {
    async sendForm() {
      this.isSubmitting = true
      try {
        await this.submitCompetition(this.competition.id, this.form)
        this.$emit('submit')
        this.competitionSubmittedDetails = {
          form: { ...this.form },
          formFields: this.competition.formFields,
        }
        this.$router.push({ name: 'competition-confirmation' })
        this.resetForm()
      } catch (error) {
        this.processBackendErrors(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="mb-5 font-bold">Entry</div>
    <validation-observer
      v-slot="{ handleSubmit, valid }"
      ref="validationObserverRef"
      slim
    >
      <form
        @submit.prevent="
          handleSubmit({
            sendForm: withAccessCheck({ callback: sendForm }),
          })
        "
      >
        <template v-for="(field, idx) in computedFormFields">
          <component
            :is="field.is"
            v-bind="field"
            :key="idx"
            v-model="form[field.name]"
            :validation="{
              rules: field.rules,
              name: field.name,
            }"
            class="w-full"
          >
            <div
              v-if="field.type === 'checkbox'"
              class="markup"
              @click="checkboxClick"
              v-html="field.label"
            />
          </component>
        </template>
        <div class="mt-10 flex w-full justify-end space-x-2.5">
          <slot name="buttons" :loading="isSubmitting" />
          <base-button
            :loading="isSubmitting"
            :disabled="isSubmitting || !valid || !canSubmit"
            class="flex-1 sm:flex-initial"
            type="submit"
          >
            Send
          </base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
